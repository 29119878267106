import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
import { ClearfixBasic } from "../../examples/utilities/Clearfix";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CodeBlock = makeShortcode("CodeBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <CodeBlock code={`<div className="clearfix">...</div>`} mdxType="CodeBlock">
  Easily clear floats by adding .clearfix to the parent element.
    </CodeBlock>
    <CodeBlock code={ClearfixBasic} mdxType="CodeBlock">
  The following example shows how the clearfix can be used. Without the
  clearfix, the wrapping div would not span the buttons, which would cause a
  broken layout.
    </CodeBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      